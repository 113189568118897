import { Pane } from "evergreen-ui";
import React from "react";
import ski from "../Images/BW_ski.jpg";
import horses from "../Images/horses.jpg";
import boats from "../Images/boats.jpg";
import statue from "../Images/statues.jpg";
import pray from "../Images/pray.jpg";
import road from "../Images/road.jpg";
import triumph from "../Images/triumph_arch.jpg";

const Gallery = () => {
  return (
    <Pane
      display="grid"
      justifyContent="center"
      gridTemplateRows="auto"
      className="gallery"
      gap={20}
    >
      {/* Colonne gauche */}
      <Pane
        display="flex"
        flexDirection="column"
        className="gallery-column"
        gap={20}
      >
        <img class="image-ski" src={ski} alt="test" />
        <img class="image-statue" src={statue} alt="test" />
        {/* <img class="image-pray" src={pray} alt="test" /> */}
        <img class="image-boats" src={boats} alt="test" />
      </Pane>

      {/* Colonne droite */}
      <Pane
        display="flex"
        flexDirection="column"
        className="gallery-column"
        gap={20}
      >
        <img class="image-horses" src={horses} alt="test" />
        <img class="image-triumph" src={triumph} alt="test" />
        <img class="image-road" src={road} alt="test" />
      </Pane>
    </Pane>
  );
};

export default Gallery;
