import { Pane } from "evergreen-ui";
import React from "react";

const Title = () => {
  return (
      <Pane
        className="sticky-header"
        display="flex"
        textAlign="center"
        flexDirection="column"
        alignItems="center"

      >
        <h1 className="main-title">PIERRE PARENT</h1>
        <h1 className="subtitle">Photograph</h1>
      </Pane>
  );
};

export default Title;
