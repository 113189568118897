import { Heading, Pane } from "evergreen-ui";
import React from "react";
import insta from "../Images/layout/instagram.svg";
import moment from "moment";

const Footer = () => {

  const date = moment().format('YYYY')

  return (
    <Pane className="footer">
      <Heading> pierreparentphotography.com© {date} </Heading>
      <Pane display="flex" alignItems="center" justifyContent="center" gap={2} >
        <Pane marginTop={7}>
          <a
            href="https://www.instagram.com/pierre.prent/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={insta} alt="instagram icon" height={25}></img>
          </a>
        </Pane>

        <a
          href="https://www.instagram.com/pierre.prent/"
          target="_blank"
          rel="noreferrer"
        >
          <Heading> pierre.prent </Heading>
        </a>
      </Pane>
    </Pane>
  );
};

export default Footer;
