import { Pane } from "evergreen-ui";
import React from "react";
import arrowdown from "../Images/layout/arrowdown.png";
import Footer from "./Footer";
import Gallery from "./Gallery";
import Title from "./Title";

const Main = () => {
  return (
    <Pane>
      <Pane className="main-title-div">
        <Title />
        <button className="main-button">
          <a href="#gallery">
            <img
              className="arrow-button"
              src={arrowdown}
              alt="arrowdonwbutton"
            />
          </a>
        </button>
      </Pane>

      <Pane className="gallery-div">
        <a id="gallery">
          <Gallery />
        </a>
      </Pane>

      <Footer />
    </Pane>
  );
};

export default Main;
